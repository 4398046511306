@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(http://fonts.gstatic.com/s/opensans/v10/cJZKeOuBrn4kERxqtaUH3VtXRa8TVwTICgirnJhmVJw.woff2)
      format("woff2");
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*------------------
Basic Definitions
------------------*/
*,
*::before,
*::after {
  font-family: inherit;
  -ms-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  line-height: 1.5;
  background-color: #fff;
  font-family: "OpenSans", sans-serif;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/*----------
GENERAL
-----------*/
.container {
  position: relative;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto 5rem;
}

.container::after {
  content: " ";
  display: table;
  clear: both;
}

.centerTitleWrap {
  -ms-display: flex;
  display: flex;
  -ms-justify-contents: center;
  -ms-align-items: center;
  justify-content: center;
  align-items: center;
  -ms-flex-flow: column;
  flex-flow: column;
}

.title {
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  color: #ff6600;
  display: inline-block;
  margin: 0.5em auto;
  line-height: 1;
  z-index: 2;
}

.title::before {
  line-height: 1;
  content: attr(data-subtitle);
  color: rgba(0, 0, 0, 0.05);
  font-weight: bold;
  position: absolute;
  top: -0.25em;
  left: -1rem;
  font-size: 3em;
  white-space: nowrap;
  z-index: -1;
}

.highlightBox {
  background-color: #f60;
  padding: 1em 0.5em;
  margin-top: 1rem;
}

.highlightBox span {
  font-weight: bold;
  color: #fff;
  font-size: 1.4rem;
  margin-left: 2rem;
}

/*----------
HEADER
-----------*/

.menu {
  display: block;
  cursor: pointer;
  overflow: hidden;
  -ms-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.menu__list {
  width: 100%;
  display: flex;
}

.menu__item {
  color: #336;
  font-size: 1.5rem;
  padding: 1em;
  text-decoration: none;
  display: inline-block;
}

.menu__item:hover,
.menu__item:active {
  text-decoration: underline wavy #336;
}

.current {
  opacity: 0.7;
}

.photo {
  margin-bottom: 2em;
}

.natiPhoto {
  height: 385px;
  background: url("../images/home/top.png") no-repeat;
  background-position: bottom left;
  background-size: contain;
}

.intro__txt {
  color: #336;
  margin-top: 1em;
  padding: 0 2em;
  line-height: 1.7;
  display: block;
  font-size: 1.2rem;
}

p.intro__txt {
  font-weight: bold;
  color: #f60;
}

p.intro__txt:first-of-type {
  margin-top: 3em;
}

@media (min-width: 600px) {
  .menu__list {
    padding-left: 5rem;
  }
  .photo {
    float: left;
    width: 45%;
  }

  .natiPhoto {
    margin-top: 8rem;
  }

  .intro__txt {
    float: right;
    width: 55%;
  }

  .top .centerTitleWrap {
    position: relative;
    left: -4em;
    margin-bottom: 0;
  }

  .top .title {
    font-size: 3rem;
    white-space: nowrap;
  }
}

@media (min-width: 1100px) {
  .menu {
    width: 100%;
    height: 4rem;
  }

  .menu__item {
    display: inline-block;
  }
}

@media (min-width: 900px) {
  .top .centerTitleWrap {
    left: -10em;
  }

  .top .title {
    font-size: 4.4rem;
  }

  .natiPhoto {
    height: 500px;
    background-image: url("../images/home/top-1280.png");
  }
}

/*----------
WORKS - HOME
-----------*/

.what {
  overflow: hidden;
  margin-bottom: 5em;
}

.what > .title {
  font-size: 1.5rem;
  margin-bottom: 2em;
}

.title > em {
  font-weight: normal;
}

.what__pic {
  float: left;
  width: 45%;
}

.what__works {
  width: 100%;
  height: 255px;
  display: block;
  background: url("../images/home/works.png") no-repeat;
  background-size: contain;
}

.what__txt {
  float: right;
  width: 55%;
}

.what__skills {
  padding: 0.5em 0.25em;
  color: #336;
  overflow: hidden;
}

.what__skills ul {
  float: right;
  list-style: disc;
  width: 90%;
  margin: 2rem 0 3rem;
}

.what__skills li {
  list-style-position: inside;
  margin-bottom: 0.5rem;
}

.siteLink {
  float: right;
  position: relative;
  padding: 0.5em;
  margin-top: 2rem;
  margin-right: 5rem;
  font-size: 1.2rem;
  background-color: #336;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: all 250ms ease ease-in-out;
}

.siteLink:hover,
.siteLink:active {
  background-color: #f60;
}

.siteLink:hover span,
.siteLink:active span {
  display: block;
}

@media (min-width: 600px) {
  .siteLink {
    font-size: 1.5rem;
  }
}

@media (min-width: 600px) and (max-width: 999px) {
  .what__txt {
    width: 100%;
    clear: both;
  }

  .what__txt .highlightBox {
    width: 90%;
    float: right;
    margin-bottom: 0.5rem;
  }

  .what__skills {
    clear: both;
  }

  .what__skills ul {
    padding-left: 2em;
  }

  .what__skills li {
    float: left;
    width: 30%;
  }

  .what__pic {
    width: 100%;
  }

  .what__works {
    margin-top: 4em;
    width: 100%;
    background-image: url("../images/home/works-640.png");
    background-position: center bottom;
  }
}

@media (min-width: 1000px) {
  .what .title {
    font-size: 3.5rem;
  }

  .what__works {
    height: 350px;
    margin-left: 5rem;
    background-image: url("../images/home/works-1440.png");
  }

  .what__skills li {
    float: left;
    width: 30%;
  }
}

/*-------
PROCESS
-------*/

.process {
  overflow: hidden;
}

.process .highlightBox {
  float: right;
  margin: 1em 0;
  width: 80%;
}

.process li {
  margin-bottom: 2em;
}

.icon {
  display: block;
  margin: 0 auto;
  width: 10rem;
}

.process__title {
  margin: 1em 0 0.5em;
  color: #f60;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.process__txt {
  padding: 0 2em 0.5em;
  font-size: 1rem;
  color: #336;
  text-align: center;
}

@media (min-width: 600px) {
  .process ul {
    width: 100%;
    overflow: hidden;
  }

  .process li {
    float: left;
    width: 50%;
    height: 22rem;
  }
}

@media (min-width: 1000px) {
  .process ul {
    padding-top: 5em;
  }

  .process li {
    float: left;
    width: 25%;
    height: 25rem;
  }
}

/*-------
FOOTER
-------*/

.bottom {
  padding-top: 1.5em;
  background-color: #eaeaea;
  overflow: hidden;
}

.bottom .title {
  color: #336;
  font-size: 2rem;
}

.bottom .title::before {
  top: -0.5em;
}

.mailLink {
  padding: 1em 0;
  display: block;
  font-size: 1.3rem;
  color: #f60;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

.bottomPic {
  height: 300px;
  width: 100%;
  background: url("../images/bottom.png") no-repeat bottom right;
  background-size: contain;
}

.social li {
  margin: 0 1.5em;
  display: inline-block;
}

.socialIcon path {
  fill: #336;
  text-decoration: none;
  -ms-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.socialIcon:hover path,
.socialIcon:active path {
  fill: #f60;
}

@media (min-width: 600px) {
  .mailLink {
    padding: 0.5em 0;
  }

  .bottom .centerTitleWrap {
    float: left;
    margin-left: 5em;
  }

  .bottomPic {
    background-image: url("../images/bottom-640.png");
  }
}

@media (min-width: 1000px) {
  .bottom .title {
    font-size: 3.2rem;
  }

  .bottomPic {
    height: 420px;
    background-image: url("../images/bottom-1024.png");
  }
}

/*-------
MORE WORKS
-------*/

.top.works {
  height: 9.5rem;
  text-align: center;
}

.top.works .title::before {
  left: -1em;
}

/*WORKS LIST*/

.portfolio {
  overflow: hidden;
  margin-bottom: 10rem;
}

.portfolio__work {
  margin-top: 10rem;
}

.portfolio__work .highlightBox {
  width: 90%;
  float: right;
  clear: both;
  color: #fff;
  font-weight: bold;
}

.work__description {
  display: block;
  clear: both;
  font-size: 1.2rem;
  padding: 2.5rem 4rem 1rem;
  color: #666;
  max-width: 100ch;
  margin-left: 1em;
}

.workStatus {
  color: #666;
  margin: 2rem 5rem;
  max-width: 500px;
}

.workTitle {
  color: #336;
  font-weight: bold;
  font-size: 1.5rem;
  display: block;
}

.workAppDone,
.work__img_AppDone {
  width: 100%;
  height: 430px;
  margin: 0 auto;
  display: block;
  background: url("../images/works/small/app_done.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.workSiteDone,
.work__img_SiteDone {
  width: 100%;
  height: 430px;
  margin: 0 auto;
  display: block;
  background: url("../images/works/small/site_done.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.workSiteEfeito,
.work__img_SiteEfeito {
  width: 100%;
  height: 430px;
  margin: 3rem auto;
  display: block;
  background: url("../images/works/small/site_efeito.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.workSiteCarreiraEAD,
.work__img_SiteCarreiraEAD {
  width: 100%;
  height: 430px;
  margin: 3rem auto;
  display: block;
  background: url("../images/works/small/site_carreiraead.png") no-repeat;
  background-size: contain;
  background-position: center;
}

.workLink {
  color: #f60;
  font-weight: bold;
  text-decoration: none;
  -ms-transition: all 250ms ease-out;
  transition: all 250ms ease-out;
}

.workLink:active,
.workLink:hover {
  opacity: 0.5;
}

@media (min-width: 640px) {
  .top.works {
    height: 12rem;
  }
  .work__img_AppDone {
    background-image: url("../images/works/medium/app_done.png");
  }
  .work__img_SiteDone {
    background-image: url("../images/works/medium/site_done.png");
  }
  .work__img_SiteEfeito {
    background-image: url("../images/works/medium/site_efeito.png");
  }
  .work__img_SiteCarreiraEAD {
    background-image: url("../images/works/medium/site_carreiraead.png");
  }
}

@media (min-width: 768px) {
  .top.works {
    height: 13rem;
  }
  .work__img_AppDone {
    background-image: url("../images/works/large/app_done.png");
  }
  .work__img_SiteDone {
    background-image: url("../images/works/large/site_done.png");
  }
  .work__img_SiteEfeito {
    background-image: url("../images/works/large/site_efeito.png");
  }
  .work__img_SiteCarreiraEAD {
    background-image: url("../images/works/large/site_carreiraead.png");
  }
}

@media (min-width: 980px) {
  .work__img_AppDone {
    background-image: url("../images/works/large/app_done.png");
  }
  .work__img_SiteDone {
    background-image: url("../images/works/large/site_done.png");
  }
  .work__img_SiteEfeito {
    background-image: url("../images/works/large/site_efeito.png");
  }
  .work__img_SiteCarreiraEAD {
    background-image: url("../images/works/large/site_carreiraead.png");
  }
  .portfolio__txt {
    float: right;
    width: 55%;
  }
  .portfolio__image {
    float: left;
    width: 45%;
  }
}
